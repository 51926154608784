import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { STORAGE_BUCKET } from "../common/constants";

export default function Footer() {
  return (
    <Box pt={6} pb={5} align="center" sx={{ backgroundColor: "#303651", color: "#eee" }}>
      <Container maxWidth="lg">
        <img src={`${STORAGE_BUCKET}/img/logo/logo_outline.png`} style={{ height: "32px" }} />
        <Box pt={1}>
          <Typography variant="body2" sx={{ color: "#BABABA" }}>
            442 5th Avenue, Suite 1902, New York, NY 10018
          </Typography>
        </Box>
        <Box py={2}>
          <Link color="inherit" style={{ padding: "1rem", fontSize: ".9rem" }} component={RouterLink} to="/privacy-policy" underline="hover">
            Privacy Policy
          </Link>
          |
          <Link color="inherit" style={{ padding: "1rem", fontSize: ".9rem" }} component={RouterLink} to="/terms-of-service" underline="hover">
            Terms of Service
          </Link>
          |
          <Link color="inherit" style={{ padding: "1rem", fontSize: ".9rem" }} href="mailto:support@bakestreet.co" underline="hover">
            Contact
          </Link>
        </Box>
        <Box  sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Link color="inherit" href="https://www.facebook.com/bakestreet.co" underline="hover">
            <Facebook />
          </Link>
          <Link color="inherit" href="https://www.instagram.com/bakestreetapp" underline="hover">
            <Instagram />
          </Link>
        </Box>
        <Typography variant="caption">&copy; {moment().format("YYYY")} BakeStreet Inc.</Typography>
      </Container>
    </Box>
  );
}
