import React from "react";
import { Avatar, Box, Button, Container, Fab, Grid, Paper, Typography } from "@mui/material";
import { STORAGE_BUCKET } from "../../common/constants";
import { Link as RouterLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RevealOnScroll from "../../common/component/animate/RevealOnScroll";
import BookDemoForm from "../components/BookDemoForm";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Highlights from "../components/Highlights";

const TESTIMONIAL = [
  /*{
    id: "-MROCJAn2bWELBXJnKQ-",
    bizName: "Sweet Touch by Iva",
    name: "Iva",
    link: "https://sweettouchbyiva.com",
    text: "BakeStreet provided the tools to take my custom cake and everyday desserts business to the next level. Sales are up, and I can manage my business from anywhere, anytime!"
  },
  {
    /*
    id: "-MY86o9vhSRH5kmdtkFq",
    bizName: "Chef JhoanEs",
    name: "Jhoan",
    link: "https://chefjhoanes.com",
    text: "Bake Street streamlined our operations, providing organization and efficiency. The user-friendly platform ensures quick, secure orders and effective weekly planning."
  },*/
  {
    id: "aqabe9A2spBl5fiN2iYb",
    bizName: "ByClio Bakery",
    name: "Clio",
    text: "Our sales grew 20% in just six months with BakeStreet! Their team truly understands bakery challenges and builds solutions that make a difference. Thanks, BakeStreet!"
  },
  {
    id: "6VRiUORmuZ7IORV5CONz",
    bizName: "Hanoli Cakes",
    name: "Monica",
    text: "BakeStreet is the common language of our bakery. I can't imagine running things without it! If you're in the custom cake business, you have to give it a try."
  },
  {
    id: "m7w2nldh2VlpJijvH2tC",
    bizName: "Mia's Brooklyn Bakery",
    name: "Agapios",
    link: "https://app.miasbrooklyn.com",
    text: "Bakestreet has revolutionized our custom order process at Mia's, leading to amazing success with custom cakes business. We're surprised at how we used to operate before Bakestreet."
  }
];

export default function Home(props) {
  return (
    <Box>
      <Box sx={{ backgroundColor: "#faf3f5" }}>
        <Container maxWidth="lg">
          <Box pt={8} pb={2}>
            <Grid container spacing={4} alignItems={"center"}>
              <Grid item sm={6} xs={12}>
                <RevealOnScroll delay="0.5">
                  <Box>
                    <Typography component="h1" sx={{ fontSize: { sm: "2.5rem", xs: "1.8rem" }, fontWeight: "700", lineHeight: 1.4 }}>
                      One system powering your <span style={{ color: "#78374a" }}>entire</span> bakery business
                    </Typography>
                    <Box my={2}>
                      <Typography sx={{ fontSize: { sm: "1.4rem", xs: "1.2rem" } }}>
                        Grow sales, streamline operations & elevate customer experience
                      </Typography>
                    </Box>
                    <Box mt={4}>
                      <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                        Book a Demo
                      </Button>
                    </Box>
                  </Box>
                </RevealOnScroll>
              </Grid>
              <Grid item sm={6} xs={12}>
                <RevealOnScroll delay="1.25">
                  <iframe
                    src="https://player.cloudinary.com/embed/?cloud_name=draqf7k7f&profile=Iva&public_id=tknzpckiiwycjdimorwb&transformation[crop]=fit,w_1080&transformation[format]=auto&tranformation[quality]=auto&transformation[video_codec]=auto&hide_logo=true"
                    width="640"
                    height="360"
                    style={{ height: "auto", width: "100%", aspectRatio: "640 / 360" }}
                    allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                    allowfullscreen
                    frameborder="0"
                  ></iframe>
                </RevealOnScroll>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#ECECEC" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#faf3f5">
          <path d="M0 0v90.2C49.7 99.9 105 82 160 65c75.5-23.3 145.5-22.4 222-3 63 16 119 14 173-8 79.5-32.4 156.2-27.6 240-10 82.6 17.4 143-1 205-31.7V0H0Z"></path>
        </svg>
        <Container maxWidth="lg">
          <Box pt={4} pb={0}>
            <Box mb={0} sx={{ textAlign: "center" }}>
              <Typography variant="h2" gutterBottom>
                Solutions to upgrade your bakery
              </Typography>
              <Typography sx={{ fontSize: { sm: "1.2rem", xs: "1rem" } }} color="textSecondary">
                The all-in-one system bakeries trust for custom quotes, online sales, payments, kitchen ops, deliveries, reviews, and marketing.
              </Typography>
            </Box>
            <Box py={7}>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <img
                      src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739337489/Custom_Cake_a7avts.png`}
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Custom Cakes & Treats
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                        Custom cakes are high margin, but endless chats, low conversion, and mistakes eat into it. BakeStreet fixes these and boosts
                        your margins.
                      </Typography>
                    </Box>
                    <Box my={2}>
                      <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                        <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                        Who doesn’t love Uber’s instant pricing? BakeStreet AI generates instant quotes while you control your pricing model.
                      </Typography>
                      <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                        <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                        Prefer manual quotes but not endless chats? BakeStreet AI guides customers to finalize design, reducing back-and-forth.
                      </Typography>
                      <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                        <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                        Hate chasing payments? BakeStreet automates deposit, add-on & balance payments. No more tracking if the order is fully paid or
                        not.
                      </Typography>
                    </Box>
                    <Box pl={4}>
                      <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                        Book a Demo
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#ECECEC" }}>
        <Container maxWidth="lg">
          <Box py={7}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
                <Box>
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      Standard Cakes & Desserts
                    </Typography>
                    <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                      Over 70% of people shop online. Make sure you reach them with an online shop made just for bakeries.
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Tired of generic e-commerce sites? BakeStreet shop is bakery specific; list items, set lead times, block days & take orders on
                      your schedule.
                    </Typography>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Sluggish online sales? BakeStreet AI tracks trends and auto updates menu for best sellers & seasonal items, driving more orders.
                    </Typography>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Trouble managing inventory? BakeStreet shows order items & quantities for selected dates, so you know what to make & when to
                      stop orders.
                    </Typography>
                  </Box>
                  <Box pl={4}>
                    <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                      Book a Demo
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739555272/OnlineShop_1_ouho2y.png`}
                    style={{ width: "100%", borderRadius: "5px", objectFit: "cover" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#ECECEC" }}>
        <Container maxWidth="lg">
          <Box py={7}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739554361/Office_1_stxupm.png`}
                    style={{ width: "100%", borderRadius: "5px", objectFit: "cover" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Back Office
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                    Old-school recipes are unbeatable, but old-school processes aren’t. Paper slips lead to mistakes, bad reviews and refunds. Level
                    up your bakery game with BakeStreet.
                  </Typography>
                </Box>
                <Box my={2}>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Missed orders? BakeStreet instantly syncs orders to in-app calendar, keeping your customers, staff & kitchen aligned.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Tired of order changes? With BakeStreet, search customers, adjust cake sizes, add cupcakes and auto-charge the balance payment.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Missed calls? BakeStreet in-app chat ensures instant connection & quick responses.
                  </Typography>
                </Box>
                <Box pl={4}>
                  <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                    Book a Demo
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#ECECEC" }}>
        <Container maxWidth="lg">
          <Box py={7}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Kitchen
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                    Every bakery kitchen is unique, but the challenges with planning, production and quality control are the same. BakeStreet helps
                    you overcome these challenges efficiently.
                  </Typography>
                </Box>
                <Box my={2}>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Spending hours on a baking plan? BakeStreet’s one-click baking plan instantly calculates how many cakes to bake, with exact
                    flavors & sizes.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Frustrated with costly mistakes? BakeStreet kitchen app centralizes order photos, size, serving, & decoration details to ensure
                    nothing is missed.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Running around kitchen to check progress? BakeStreet app lets you monitor baking, icing and decoration progress from anywhere,
                    anytime.
                  </Typography>
                </Box>
                <Box pl={4}>
                  <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                    Book a Demo
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739558536/Kitchen_2_ppjmlc.png`}
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#ececec" }}>
        <Container maxWidth="lg">
          <Box py={7}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739503396/delivery_2_os9lsj.png`}
                    style={{ width: "100%", borderRadius: "5px", objectFit: "cover" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Delivery
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                    Delivery option can potentially increase sales by 10-20% on average. Offer delivery to boost your bakery’s revenue with ease.
                  </Typography>
                </Box>
                <Box my={2}>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Not offering integrated delivery? BakeStreet lets customers select delivery at checkout, with prices automatically calculated.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Spending hours on delivery planning? BakeStreet delivery planner generates schedules and prints delivery slips with just
                    one-click.
                  </Typography>
                  <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Need delivery providers? BakeStreet integrates with delivery providers for expert cake handling, live tracking & automated
                    pricing.
                  </Typography>
                </Box>
                <Box pl={4}>
                  <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                    Book a Demo
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#ECECEC" }}>
        <Container maxWidth="lg">
          <Box py={7}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/f_auto,q_70,w_760/v1739503647/SEO_8_nuueav.png`}
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
                <Box>
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      Growth
                    </Typography>
                    <Typography sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }} color="textSecondary">
                      Over 90% of customers search online and check reviews before choosing a business. Stand out with strong online visibility and
                      great reviews.
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Losing to competitors? BakeStreet builds an SEO optimized website that helps more customers discover your bakery online.
                    </Typography>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Struggling to build online reputation? BakeStreet AI helps you consistently earn more 5-star Google reviews to boost your
                      credibility.
                    </Typography>
                    <Typography py={0.75} sx={{ display: "flex", fontSize: { xs: "0.85rem", sm: "0.90rem" } }}>
                      <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                      Want more repeat business? BakeStreet AI sends personalized texts at the perfect time, bringing back customers and boosting
                      repeat orders.
                    </Typography>
                  </Box>
                  <Box pl={4}>
                    <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                      Book a Demo
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* <Box style={{ backgroundColor: "#ececec" }}>
        <Container maxWidth="lg">
          <Box sx={{ transform: "rotateY(180deg)" }}>
          <svg data-name="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" fill="#faf3f5"></path>
          </svg>
        </Box>
          <Box py={7}>
            <Grid container spacing={4} >
              <Grid item sm={6} xs={12}>
                <Box>
                  <img
                    src={`https://res.cloudinary.com/draqf7k7f/image/upload/v1738989226/Customer_Retention_pewfx0.jpg`}
                    style={{ width: "100%", borderRadius: "5px", objectFit: "cover" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Customer Retention
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    You love repeat business but can’t get customers to come back? Wish you could remember every birthday, anniversary, and holiday to
                    send the right offers? BakeStreet’s smart marketing keeps customers coming back—automatically!
                  </Typography>
                </Box>
                <Box my={2}>
                  <Typography py={0.75} style={{ display: "flex",  fontSize: ".85em" }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Grows repeat business by reaching past customers at the perfect time with automated marketing.
                  </Typography>
                  <Typography py={0.75} style={{ display: "flex",  fontSize: ".85em" }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Personalized texts that drive higher open rates, stronger customer connections, and more orders.
                  </Typography>
                  <Typography py={0.75} style={{ display: "flex",  fontSize: ".85em" }}>
                    <TaskAltIcon style={{ fontSize: "1.4rem", color: "#78374a ", marginRight: "7px" }} />
                    Increase lifetime customer value with AI reminders, driving repeat orders for celebrations year after year!
                  </Typography>
                </Box>
                                    <Box pl={4}>

                  <Button size="large" color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                    Book a Demo
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>*/}
      <Box style={{ backgroundColor: "#faf3f5", textAlign: "center" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#ececec">
          <path d="M0 0v90.2C49.7 99.9 105 82 160 65c75.5-23.3 145.5-22.4 222-3 63 16 119 14 173-8 79.5-32.4 156.2-27.6 240-10 82.6 17.4 143-1 205-31.7V0H0Z"></path>
        </svg>
        <Container maxWidth="md">
          <Box py={4}>
            <Typography variant="h2" sx={{ lineHeight: 1.5 }}>
              Trusted by the top <br />
              <span style={{ color: "#78374a" }}>tech-forward</span>
              &nbsp;bakeries
            </Typography>
            <Box mt={4} sx={{ maxWidth: "90vw" }}>
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                transitionTime={1000}
                stopOnHover={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                swipeable={false}
              >
                {TESTIMONIAL.map((o, idx) => {
                  return (
                    <Box px={2}>
                      <Paper sx={{ backgroundColor: "#FFFFFF", textAlign: "left" }} elevation={0}>
                        <Box p={3}>
                          <Typography variant="subtitle1" gutterBottom>
                            {o.text}
                          </Typography>
                          <Box mt={2} sx={{ display: "flex", gap: "15px" }}>
                            <Avatar src={`${STORAGE_BUCKET}/img/b/${o.id}/logo400.webp`} style={{ width: "50px", height: "50px" }} />
                            <Box>
                              <Typography variant="h6">{o.name}, Owner</Typography>
                              <Typography variant="body2">{o.bizName}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  );
                })}
              </Carousel>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#ECECEC" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#faf3f5">
          <path d="M0 0v90.2C49.7 99.9 105 82 160 65c75.5-23.3 145.5-22.4 222-3 63 16 119 14 173-8 79.5-32.4 156.2-27.6 240-10 82.6 17.4 143-1 205-31.7V0H0Z"></path>
        </svg>
        <Container maxWidth="lg">
          <Box pt={4} pb={15}>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <Box mb={2} textAlign={"center"}>
                  <Typography variant="h3" gutterBottom>
                    Upgrade your Bakery
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }} color="textSecondary">
                    Schedule a demo to see how BakeStreet can help boost revenue, streamline operations & delight customers.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <BookDemoForm />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Highlights />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Fab color="primary" sx={{ position: "fixed", bottom: "20px", right: "20px" }} onClick={() => window.$zoho.salesiq.chat.start()}>
        <ChatBubbleIcon />
      </Fab>
    </Box>
  );
}
